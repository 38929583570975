var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "project-listing-header"
  }, [_c('div', {
    staticClass: "listing-main-info",
    on: {
      "click": function ($event) {
        return _vm.$emit('openListingPanel', _vm.listing._id);
      }
    }
  }, [_vm.listing.images.length > 0 ? _c('img', {
    staticClass: "listing-image",
    attrs: {
      "src": _vm.listing.images[0].thumbnail || _vm.listing.images[0].name,
      "alt": ""
    }
  }) : _c('div', {
    staticClass: "listing-image"
  }), _c('div', {
    staticClass: "listing-main-info-text"
  }, [_c('div', {
    key: _vm.listingNameUpdater,
    staticClass: "content-semibold listing-name"
  }, [_vm._v(" " + _vm._s(_vm.listing.listingName) + " ")]), _vm.listing.description ? _c('div', {
    staticClass: "content-small listing-description"
  }, [_vm._v(" " + _vm._s(_vm.listing.description) + " ")]) : _vm._e(), _vm.openHouse ? _c('OpenHouseContainer', {
    attrs: {
      "task": _vm.openHouse,
      "invert": true
    }
  }) : _vm._e()], 1)]), _c('div', {
    staticClass: "listing-additional-info"
  }, [_c('div', {
    staticClass: "listing-estate-type"
  }, [_vm._v(" " + _vm._s(_vm.$lfMapper.estateType(_vm.listing.estateType)) + " ")]), _vm.listing.generalSurfaceArea ? _c('div', {
    staticClass: "listing-general-surface-area"
  }, [_vm._v(" " + _vm._s(_vm.listing.generalSurfaceArea) + " (m²) ")]) : _vm._e(), _vm.listing.price ? _c('v-row', {
    staticClass: "listing-price",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": _vm.openDropdown
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.listingPrice) + " (€)")]), _vm.listing.priceHistoryLog && _vm.listing.priceHistoryLog.length && _vm.dropdownIsOpen ? _c('div', {
    staticClass: "price-info-container price-info-container-hover"
  }, [_c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeDropdown,
      expression: "closeDropdown"
    }],
    staticClass: "price-history-info-dropdown"
  }, _vm._l(_vm.listing.priceHistoryLog.slice(-20), function (info, index) {
    return _c('div', {
      key: 'price-info-' + index,
      staticClass: "price-info"
    }, [_c('div', {
      staticClass: "content-normal"
    }, [_vm._v(" " + _vm._s(_vm.formatDate(info.date)) + " ")]), _c('div', {
      staticClass: "content-semibold",
      staticStyle: {
        "width": "100%",
        "text-align": "right"
      }
    }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(info.to, "€")) + " ")])]);
  }), 0)]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "listing-status"
  }, [_c('div', {
    staticClass: "listing-status-top"
  }, [_c('StatusLabel', {
    attrs: {
      "disabled": _vm.listing.status === 'archived' && (_vm.dealPipelineStep === 'archived' || _vm.dealPipelineStep === 'Done'),
      "status": _vm.listing.status,
      "listing": _vm.listing
    },
    on: {
      "statusChanged": function (data) {
        return _vm.$emit('statusChanged', data);
      },
      "open-booking-modal": _vm.openBookingDetailsModal,
      "onPress": function (event) {
        return _vm.$emit('openStatusDropdown', _vm.listing, event);
      }
    }
  }), _c('v-tooltip', {
    key: _vm.bookingUpdater,
    attrs: {
      "bottom": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_vm.listing.status === 'booked' ? _c('p', _vm._g(_vm._b({
          staticClass: "booked-label booked-info-label-listing"
        }, 'p', attrs, false), on), [_c('img', {
          staticStyle: {
            "filter": "invert(1)"
          },
          attrs: {
            "src": require("@/assets/images/info.svg"),
            "alt": ""
          },
          on: {
            "click": function ($event) {
              return _vm.openBookingDetailsModal(_vm.listing);
            }
          }
        })]) : _vm._e()];
      }
    }])
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("BookingDetails")) + " ")])]), _vm.listing.status === 'active' && _vm.listing.dateAvailable && !_vm.listing.activeUntil ? _c('p', {
    staticClass: "content-small"
  }, [_vm._v(" " + _vm._s(_vm.$t("available")) + " " + _vm._s(_vm.formatDate(_vm.listing.dateAvailable)) + " ")]) : _vm._e(), _vm.listing.status === 'expired' && _vm.listing.expiryDate ? _c('p', {
    staticClass: "content-small",
    staticStyle: {
      "margin-left": "8px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$lfMapper.expiryDate(_vm.listing.expiryDate)) + " ")]) : _vm._e(), _vm.listing.status === 'booked' && _vm.listing.bookedUntil ? _c('p', {
    staticClass: "content-small booking-until-date",
    on: {
      "click": function ($event) {
        return _vm.openBookingDetailsModal(_vm.listing);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("until")) + " " + _vm._s(_vm.formatDate(_vm.listing.bookedUntil)) + " ")]) : _vm._e(), _vm.listing.status === 'active' && _vm.listing.activeUntil ? _c('date-pick', {
    attrs: {
      "format": 'DD.MM.YYYY'
    },
    on: {
      "input": function (value) {
        return _vm.$emit('changeActiveUntil', _vm.listing, value);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var toggle = _ref2.toggle;
        return [_vm.listing.status === 'active' && _vm.listing.activeUntil ? _c('p', {
          staticClass: "content-small",
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              return toggle.apply(null, arguments);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("until")) + " " + _vm._s(_vm.listing.activeUntil) + " ")]) : _vm._e()];
      }
    }], null, false, 1224337447),
    model: {
      value: _vm.listing.activeUntil,
      callback: function ($$v) {
        _vm.$set(_vm.listing, "activeUntil", $$v);
      },
      expression: "listing.activeUntil"
    }
  }) : _vm._e()], 1), !_vm.isProjectInactive && (_vm.listing.status === 'active' || _vm.listing.status === 'booked') ? _c('OutputsTags', {
    attrs: {
      "listing": _vm.listing
    },
    on: {
      "toggleModal": function ($event) {
        _vm.modalPosition = $event;
        _vm.toggleOutputsModal = !_vm.toggleOutputsModal;
      }
    }
  }) : _vm._e()], 1), !_vm.dealPipelineStep || _vm.listing.status !== 'archived' || _vm.listing.status === 'archived' && _vm.dealPipelineStep && _vm.dealPipelineStep !== 'archived' && _vm.dealPipelineStep !== 'Done' ? _c('IconDropdown', {
    attrs: {
      "items": _vm.additionalActivities,
      "display-key": 'name',
      "value-key": 'value',
      "icon": 'dot_menu.svg',
      "project-view": true
    },
    on: {
      "itemSelected": function (item) {
        return _vm.$emit('selectActivity', item, _vm.listing);
      }
    }
  }) : _vm._e(), _vm.bookingDetailsModalOpen ? _c('BookingDetailsModal', {
    attrs: {
      "opened-listing": _vm.bookedListing
    },
    on: {
      "update-listing": _vm.changeListingStatus,
      "close-modal": function ($event) {
        _vm.bookingDetailsModalOpen = false;
      }
    }
  }) : _vm._e(), _c('OutputsModal', {
    attrs: {
      "position": _vm.modalPosition,
      "toggle-modal": _vm.toggleOutputsModal,
      "listing": _vm.listing
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }