<template>
  <div class="overlay" id="overlay-project">
    <div id="panel-wrapper-project" class="modal-wrapper">
      <div class="content-overlay" id="content-wrapper">
        <div class="sticky-header" style="z-index: 126">
          <v-row class="top-row" style="height: 24px; padding-left: 32px">
            <h4 style="height: 24px">
              {{ $t("CompleteProject") }}
            </h4>
          </v-row>
        </div>
        <v-divider
          style="top: 97px; z-index: 126; position: fixed; width: 560px"
        />

        <div style="padding: 129px 0px; padding-right: 32px">
          <v-row class="archive-info">
            <img :src="require('@/assets/images/info.svg')" alt="" />
            <p>{{ $t("ListingsArchivedInfo") }}</p>
          </v-row>

          <v-row style="padding-bottom: 12px; padding-left: 32px">
            <h5>{{ $t("Subblock.GeneralData") }}</h5>
          </v-row>

          <v-row style="padding-left: 32px">
            <v-col class="pa-0">
              <v-row class="faded-title required" title="required">
                <p class="content-small-semibold">
                  {{ $t("EndingReason") }}
                </p>
              </v-row>
              <v-row style="gap: 8px">
                <span
                  v-for="(projectReason, index) in projectEndReasons"
                  :key="index"
                  @click="setProjectReason(projectReason)"
                >
                  <ProjectEndReasonContainer
                    :project-end-reason="projectReason"
                    :class="
                      'project-type-con ' +
                      (projectReason === form.projectEndReason
                        ? 'project-type-selected-con-' + projectReason
                        : null)
                    "
                  />
                </span>
              </v-row>
            </v-col>
          </v-row>
          <!--  <v-row
          style="padding-left: 32px;"
            class="flex-column"
            v-if="
              this.form.dealType !== 'Sale' && this.form.dealType !== 'LeaseOut'
            "
          >
            <BaseInput
              :id="'project-name'"
              :placeholder="$t('ProjectName')"
              v-model="form.name"
              :required="true"
              :error="showErrors && !isFieldValid('name') ? 'not-valid' : ''"
              :error-message="$t('FormErrors.' + errors.name)"
            />
          </v-row> -->
          <!--          <v-row-->
          <!--              v-if="form.projectEndReason === 'Won'"-->
          <!--              style="padding-left: 32px; margin-top: 24px"-->
          <!--          >-->
          <!--            <BaseDropdown-->
          <!--                icon-left="calendar_20_20.svg"-->
          <!--                :id="'expected-closing-date'"-->
          <!--                :placeholder="$t('ClosingDate')"-->
          <!--                v-model="form.closingDate"-->
          <!--                :show-dropdown-arrow="true"-->
          <!--                type="date"-->
          <!--            />-->
          <!--          </v-row>-->
          <v-row
            v-if="form.projectEndReason === 'Won'"
            style="
              gap: 12px;
              display: flex;
              margin-top: 12px;
              padding-left: 32px;
              width: 100%;
              flex-wrap: nowrap;
            "
          >
            <BaseInput
              :id="'project-amount-field'"
              :placeholder="$t('DealValue')"
              v-model="form.amount"
            />
            <BaseDropdown
              :id="'project-currency-field'"
              v-model="form.currency"
              :items="currencies"
              :show-dropdown-arrow="true"
              :dropdown-allow-null="false"
              :placeholder="$t('Currency')"
              :type="'dropdown'"
            />
          </v-row>
          <v-row
            v-if="form.projectEndReason === 'Won'"
            style="padding-left: 32px; margin-top: 12px"
          >
            <BaseDropdown
              :key="inputUpdater"
              icon-left="calendar_20_20.svg"
              :id="'expected-closing-date'"
              :placeholder="$t('DateOfTransaction')"
              v-model="form.dateOfTransaction"
              :show-dropdown-arrow="true"
              type="date"
              required="true"
              :error-message="
                errors && errors.dateOfTransaction
                  ? $t('FormErrors.' + errors.dateOfTransaction)
                  : ''
              "
              :error="showErrors && !isFieldValid('dateOfTransaction')"
            />
          </v-row>
          <v-row
            style="padding-left: 32px"
            v-if="form.projectEndReason === 'Lost'"
          >
            <v-radio-group
              style="padding-top: 12px"
              v-model="form.backedOutPerson"
              row
            >
              <v-radio
                color="#FF5C01"
                value="broker"
                class="radio-button content"
                :label="$t('BrokerBackedOut')"
              ></v-radio>
              <v-radio
                color="#FF5C01"
                value="client"
                class="radio-button content"
                :label="$t('ClientBackedOut')"
              ></v-radio>
            </v-radio-group>
          </v-row>
          <v-row
            style="padding-left: 32px"
            v-if="form.projectEndReason === 'Cancelled'"
          >
            <v-radio-group
              style="padding-top: 12px"
              v-model="form.cancelledPerson"
              row
            >
              <v-radio
                color="#FF5C01"
                value="broker"
                class="radio-button content"
                :label="$t('BrokerCancelled')"
              ></v-radio>
              <v-radio
                color="#FF5C01"
                value="client"
                class="radio-button content"
                :label="$t('ClientCancelled')"
              ></v-radio>
            </v-radio-group>
          </v-row>
          <span v-if="form.projectEndReason === 'Lost'">
            <v-row style="padding-left: 32px; margin-top: 24px">
              <BaseTextarea
                :id="'project-end-add-reason'"
                v-model="form.lostReason"
                :placeholder="$t('Reason')"
              />
            </v-row>
          </span>
          <span v-if="form.projectEndReason === 'Cancelled'">
            <v-row style="padding-left: 32px; margin-top: 24px">
              <BaseTextarea
                :id="'project-end-add-reason'"
                v-model="form.cancelledReason"
                :placeholder="$t('Reason')"
              />
            </v-row>
          </span>
          <div
            v-if="form.projectEndReason === 'Won' && isFeedbackEnabled"
            style="padding-left: 32px; margin-top: 24px"
          >
            <div>{{ $t("SendOutFeedbackQuestionnaire") + ":" }}</div>
            <div
              v-for="contact in this.contacts"
              :key="contact.email"
              style="display: flex; align-items: center; margin-top: 12px"
            >
              <BaseToggle
                v-model="contact.sendFeedbackEmail"
                :label="contact.name + ' (' + contact.email + ')'"
              />
            </div>
          </div>
          <div class="modal-footer">
            <v-divider
              style="z-index: 111; position: fixed !important; width: 560px"
            ></v-divider>
            <div class="buttons-row">
              <div class="buttons-wrapper">
                <button v-if="!isEdit" @click="closeModal()" class="cancel">
                  {{ $t("Cancel") }}
                </button>
                <SubmitButton
                  :submitPlaceholder="true"
                  :loading="isLoading"
                  @clicked="saveEndState()"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import ClickOutside from "vue-click-outside";
import ProjectEndReasonContainer from "@/components/common/ProjectEndReasonContaine";
//import contactApi from "@/http/contact";

import BaseDropdown from "@/components/common/BaseDropdown";
import BaseInput from "@/components/common/BaseInput";
import BaseTextarea from "@/components/common/BaseTextarea";

import SubmitButton from "@/components/common/SubmitButton";
import moment from "moment";
import BaseToggle from "@/components/common/BaseToggle";

export default {
  components: {
    BaseToggle,
    ProjectEndReasonContainer,
    //DealTypeContainer,
    BaseDropdown,
    BaseInput,
    BaseTextarea,
    SubmitButton,
    //EstonianAddressFinder,
  },
  name: "SidepanelAdd",
  props: ["projectEndData", "isEdit", "deal"],
  directives: {
    ClickOutside,
  },
  data() {
    return {
      contact: null,
      isLoading: false,
      showErrors: false,
      isDeleteModalOpen: false,
      noteContent: "",
      inputUpdater: 0,
      validate: ["dateOfTransaction"],
      projectEndReasons: ["Won", "Lost", "Cancelled"],
      currencies: [
        { name: "EUR", value: "EUR" },
        { name: "USD", value: "USD" },
      ],
      errors: null,
      transaction: null,
      contacts: [],
      form: {
        projectEndReason: "Won",
        amount: "",
        currency: "EUR",
        backedOutPerson: "broker",
        cancelledPerson: "broker",
        closingDate: null,
        dateOfTransaction: null,
        lostReason: null,
        cancelledReason: null,
        contacts: [],
        creator: {
          userId: null,
          name: null,
          email: null,
          companyId: null,
        },
        brokerageFee: 0,
      },
    };
  },
  async created() {
    await this.checkForTransactionData();
    await this.getCompanyPolicies();
    if (this.isFeedbackEnabled) {
      await this.getProjectContactPerson();
    }
    if (this.deal.endState && this.deal.endState.length > 0) {
      this.form = this.deal.endState[this.deal.endState.length - 1];
      this.inputUpdater++;
    }
  },
  mounted() {
    this.errors = this.getEmptyErrors();
    this.hideOverlay();
  },
  watch: {},
  beforeDestroy() {
    document.documentElement.style.overflow = "visible";
  },
  computed: {
    ...mapGetters(["customers", "user"]),
    isFeedbackEnabled() {
      if (this.user.companyPolicies.projects.ending.feedbackEnabled) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(["changeSidepanelStateDeals", "getCompanyPolicies"]),
    closeModal() {
      this.$emit("closed");
    },
    async checkForTransactionData() {
      const response = await axios.get(
        `/api/transaction/project/${this.deal._id}`
      );
      let transaction = response.data.transaction;
      this.transaction = transaction;
      if (transaction && transaction.dealValue) {
        this.form.amount = transaction.dealValue;
      }
      if (transaction && transaction.transactionDate) {
        this.form.dateOfTransaction = transaction.transactionDate;
        this.inputUpdater++;
      }
    },
    async getProjectContactPerson() {
      if (this.transaction) {
        let response = await axios.get(
          `/api/transaction/seller-buyer-info/${this.transaction.projectId}`
        );
        this.contacts = response.data.contacts;
        this.form.contacts = this.contacts;
      }
    },
    async saveEndState() {
      if (!this.isFormValid()) {
        this.showErrors = true;
        this.toastError(this.$t("FormErrors.AllRequiredFieldsMustBeFilled"));
        return;
      }
      if (
        this.deal.dealType === "Sale" &&
        this.deal.aml.length < 1 &&
        this.form.projectEndReason === "Won"
      ) {
        this.toastError(this.$t("CompleteKnowYourCustomerFirst"));
        return;
      }

      this.form.creator.userId = this.user._id;
      this.form.creator.name = this.user.name;
      this.form.creator.email = this.user.email;
      this.form.creator.companyId = this.user.companyId;

      if (
        this.form.amount &&
        (this.deal.brokerFeePercentage || this.deal.minimumFee)
      ) {
        const projectValue = parseInt(this.form.amount.replace(/\s/g, ""));
        const brokerFeePercentage = this.deal.brokerFeePercentage
          ? parseFloat(this.deal.brokerFeePercentage.split(",").join("."))
          : null;
        const minimumFee = this.deal.minimumFee
          ? parseInt(this.deal.minimumFee)
          : null;

        let calculatedValue = null;
        if (brokerFeePercentage) {
          calculatedValue = (brokerFeePercentage / 100) * projectValue;
        }

        if (minimumFee && (!calculatedValue || calculatedValue < minimumFee)) {
          this.form.brokerageFee = minimumFee;
        } else if (calculatedValue) {
          this.form.brokerageFee = calculatedValue;
        }
      }

      let projectEndState = this.form;
      //projectEndState._id = nanoid(8);
      this.isLoading = true;
      this.deal.pipelineStep = "Done";
      let response = await axios.post(`/api/project/end/${this.deal._id}`, {
        projectEndState,
      });
      if (response.data === 0) {
        this.toastError(this.$t("ErrorCreating"));
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.closeModal();
        if (this.form.projectEndReason === "Won") {
          this.$emit("projectWon");
        } else {
          this.$emit("saveResponse", response.data);
        }
      }
    },
    isFormValid() {
      this.showErrors = false;
      //this.errors = this.getEmptyErrors();
      return this.validate
        .map((field) => {
          return this.isFieldValid(field);
        })
        .every((item) => item === true);
    },
    isFieldValid(fieldName) {
      switch (fieldName) {
        case "dateOfTransaction":
          if (!this.form.dateOfTransaction) {
            this.errors.dateOfTransaction = "FieldRequired";
            return false;
          }
          break;
        case "name":
          if (!this.form.name) {
            this.errors.name = "FieldRequired";
            return false;
          }
          break;
        case "object":
          if (!this.object) {
            this.errors.object = "FieldRequired";
            return false;
          }
          break;
        case "estateType":
          if (!this.form.estateType) {
            this.errors.estateType = "FieldRequired";
            return false;
          }
          break;
        case "contactPerson":
          if (!this.form.contactPerson) {
            this.errors.contactPerson = "FieldRequired";
            return false;
          }
          break;
      }

      return true;
    },
    getEmptyErrors() {
      return {
        name: "",
        object: "",
        estateType: "",
        contactPerson: "",
      };
    },
    setProjectReason(projectEndReason) {
      this.form = {
        projectEndReason: projectEndReason,
        amount: "",
        currency: "EUR",
        backedOutPerson: "broker",
        cancelledPerson: "broker",
        closingDate: null,
        dateOfTransaction: moment().format("YYYY-MM-DD"),
        lostReason: null,
        cancelledReason: null,
        creator: {
          userId: null,
          name: null,
          email: null,
          companyId: null,
        },
      };
      this.inputUpdater++;
    },
    setContactPerson(contactPerson) {
      this.form.contactPerson = contactPerson;
    },
    setEstateType(estateType) {
      this.form.estateType = estateType;
    },
    /*  setCurrency(currency) {
      this.form.currency = currency.name;
    }, */
    setObject(object) {
      this.form.objects = [];
      this.object = object;
      this.form.objects.push(this.object);
    },
    removeObject() {
      this.object = null;
      this.form.objects.pop();
    },
    removeContact() {
      this.form.contactPerson = null;
    },
    putImages(e) {
      this.form.images = [];
      e.forEach((el) => {
        if (el.name) {
          this.form.images.push({ name: el.name, hidden: false });
        }
      });
    },
    dealTypeSaleOrRent() {
      return (
        this.form.dealType === "Sale" ||
        this.form.dealType === "LeaseOut" ||
        this.form.dealType === "Lease Out"
      );
    },
    handleChange() {
      if (this.form.probability < 0) {
        this.form.probability = 0;
      }
      if (this.form.probability > 100) {
        this.form.probability = 100;
      }
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    hideOverlay() {
      document.documentElement.style.overflow = "hidden";

      window.onclick = (e) => {
        if (e.target.classList[0] === "overlay") {
          this.closeModal();
          e.target.style.display = "none";
        }
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/global_variables";

#panel-wrapper-project {
  -webkit-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
}

#wrapped-header-project {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-start;
  padding: 48px 24px 24px;
  border-right: 1px solid $grey500;

  h4 {
    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    text-align: center;
    position: relative;
    justify-self: center;
    align-self: center;
  }
}

::v-deep .v-label {
  font-size: 14px;
}

.deal-type-lable {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #75787a !important;
}

.v-text-field--outlined .v-label {
  top: 20px;
}

::v-deep .delete {
  padding: 0 !important;
}

::v-deep .plus {
  padding-top: 1px;
}

.calender-label {
  margin-top: -5px;
  left: auto;
  right: auto;
  position: absolute;
  font-size: 12px;
  font-family: Inter;
  background-color: white;
  margin-left: 12px;
}

.sticky-header {
  justify-content: flex-end;
  z-index: 111;
  padding-top: 40px;
  position: fixed;
  background: white;
  height: 97px;
  width: 560px;
}

.textarea:focus {
  border: 1px solid #e6e8ec;
  outline: none;
  border-radius: 8px;
}

.top-row {
  width: 100%;
  justify-content: space-between;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 8;
}

.flex-column-gen {
  margin-top: 17px !important;
}

::v-deep .mainMessage {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  //line-height: 20px;
  text-align: center;
  color: #000000;
}

::v-deep .beforeUpload .icon {
  height: 20px;
}

.image-uploader {
  //min-width: 896px;
  min-height: 96px;
  border: 1px dashed #c3c4c6;
  box-sizing: border-box;
  border-radius: 8px;
}

.project-type-selected-con-Won {
  background: #b5e7cf !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-Lost {
  background: #ffb4b6 !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-Cancelled {
  background: #ffb4b6 !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-con {
  font-style: normal;
  font-weight: normal;
  border: 1px solid $grey500;
  box-sizing: border-box;
  border-radius: 8px;
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
}

.modal-wrapper {
  position: absolute;
  right: 0;
  top: 0%;
  bottom: 0%;
  background-color: #ffffff;
  padding-left: 0px;
  z-index: 7;
  width: 560px;
  border: 1px solid #e6e8ec;
  overflow-y: scroll;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

.modal-header {
  justify-content: flex-end;
  z-index: 111;
  padding-top: 40px;
  position: fixed;
  background: white;
  height: 97px;
  width: 560px;
}

.modal-content {
  font-size: 14px;
  padding: 110px 32px 140px 32px;
}

.modal-footer {
  justify-content: flex-end;
  padding-bottom: 64px;
  z-index: 110;
  position: fixed;
  background: white;
  bottom: 0;
  height: 104px;
  width: 560px;
}

.faded-title {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $grey950;
}

.buttons-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 8px;
}
.buttons-row {
  justify-content: flex-end;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 32px 32px 40px 32px;
  font-family: Inter !important;
  color: #000000;
  width: 560px;
  background-color: white;

  .cancel {
    background-color: transparent !important;
    box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    padding: 12px;
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
}

::v-deep .theme--light.v-select .v-select__selections {
  color: #000000;
  line-height: 20px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  z-index: 55;
  left: 16px;
  cursor: pointer;
}

input::-webkit-datetime-edit {
  position: relative;
  left: 18px;
}

input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 18px;
}

.birth-class {
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  width: 49.2%;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  height: 56px;
  padding: 0px !important;

  &:focus {
    outline: none !important;
  }
}

.save-button {
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  line-height: 16px;
  text-transform: none;
  width: 103px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.cancel-button {
  background-color: transparent !important;
  box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400 !important;
  line-height: 16px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

.close-button {
  background: #f4f5f7;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}

.textarea {
  width: 100%;
  height: 96px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e6e8ec;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
}

.delete-button {
  margin-right: 168px;
  background-color: transparent !important;
  box-shadow: 0px 0 2px #ff1e24;
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  border-radius: 8px;
  color: #ff1e24;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.feedback-email {
  margin-left: 12px;
}
.archive-info {
  padding: 0 0 24px 32px;
  align-items: center;
  gap: 8px;

  img {
    width: 20px;
    height: 20px;
  }
}

::v-deep .not-valid fieldset {
  border: 2px solid #ff1e24 !important;
}

.input-edit {
  margin-top: 12px;
}

::v-deep .v-messages {
  display: initial;
}

::v-deep .radio-group-label .v-label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

::v-deep .v-input__control {
  height: 24px;
  flex-direction: unset;
}
</style>
