var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay",
    attrs: {
      "id": "overlay-project"
    }
  }, [_c('div', {
    staticClass: "panel-wrapper",
    attrs: {
      "id": "panel-wrapper-project"
    }
  }, [_c('div', {
    staticClass: "content-overlay",
    attrs: {
      "id": "content-wrapper"
    }
  }, [_c('div', {
    staticClass: "sticky-header"
  }, [_c('v-row', {
    staticClass: "top-row",
    staticStyle: {
      "height": "24px",
      "padding-left": "32px"
    }
  }, [!_vm.isEdit ? _c('h4', {
    staticStyle: {
      "height": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddAmlProcedureRules")) + " ")]) : _vm._e(), _vm.isEdit ? _c('h4', {
    staticStyle: {
      "height": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ChangeAmlProcedureRules")) + " ")]) : _vm._e()])], 1), _c('v-divider', {
    staticStyle: {
      "top": "97px",
      "z-index": "111",
      "position": "fixed",
      "width": "560px"
    }
  }), _c('v-row', {
    staticStyle: {
      "gap": "8px",
      "padding-left": "32px",
      "padding-right": "32px",
      "padding-top": "129px"
    }
  }, [_c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "id": 'aml-active-business',
      "placeholder": _vm.$t('ActiveBusinessRelationshipFrom'),
      "show-dropdown-arrow": true,
      "error-message": _vm.errors && _vm.errors.activeBusiness ? _vm.$t('FormErrors.' + _vm.errors.activeBusiness) : '',
      "required": "true",
      "error": _vm.isErrorActiveBusiness && !_vm.isFieldValid('activeBusinessNew'),
      "type": "date"
    },
    model: {
      value: _vm.form.activeBusiness,
      callback: function ($$v) {
        _vm.$set(_vm.form, "activeBusiness", $$v);
      },
      expression: "form.activeBusiness"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "gap": "8px",
      "margin-top": "10px !important",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "id": 'aml-business-relationship-to',
      "placeholder": _vm.$t('ActiveBusinessRelationshipTo'),
      "show-dropdown-arrow": true,
      "type": "date"
    },
    model: {
      value: _vm.form.businessRelationship,
      callback: function ($$v) {
        _vm.$set(_vm.form, "businessRelationship", $$v);
      },
      expression: "form.businessRelationship"
    }
  })], 1), _c('v-row', {
    staticClass: "deal-type",
    staticStyle: {
      "padding-left": "32px",
      "padding-right": "32px",
      "margin-top": "14px"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('RepresentationContract'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.representationContract,
      callback: function ($$v) {
        _vm.$set(_vm.form, "representationContract", $$v);
      },
      expression: "form.representationContract"
    }
  })], 1), _c('v-row', {
    staticClass: "deal-type",
    staticStyle: {
      "padding-left": "32px",
      "padding-right": "32px",
      "margin-top": "14px"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('PowerOfAttorney'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.powerOfAttorney,
      callback: function ($$v) {
        _vm.$set(_vm.form, "powerOfAttorney", $$v);
      },
      expression: "form.powerOfAttorney"
    }
  })], 1), _c('v-row', {
    staticClass: "deal-type",
    staticStyle: {
      "padding-left": "32px",
      "padding-right": "32px",
      "margin-top": "14px"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('CustomerConfirmation'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.customerConfirmation,
      callback: function ($$v) {
        _vm.$set(_vm.form, "customerConfirmation", $$v);
      },
      expression: "form.customerConfirmation"
    }
  })], 1), _c('v-row', {
    staticClass: "deal-type",
    staticStyle: {
      "padding-left": "32px",
      "padding-right": "32px",
      "margin-top": "14px"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('ServicesRequested'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.servicesRequested,
      callback: function ($$v) {
        _vm.$set(_vm.form, "servicesRequested", $$v);
      },
      expression: "form.servicesRequested"
    }
  })], 1), _c('v-row', {
    staticClass: "deal-type",
    staticStyle: {
      "padding-left": "32px",
      "padding-right": "32px",
      "margin-top": "14px"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('PrintoutOfRegister'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.printoutOfRegister,
      callback: function ($$v) {
        _vm.$set(_vm.form, "printoutOfRegister", $$v);
      },
      expression: "form.printoutOfRegister"
    }
  })], 1), _c('v-row', {
    staticClass: "deal-type",
    staticStyle: {
      "padding-left": "32px",
      "padding-right": "32px",
      "margin-top": "14px"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('CopyOfIdenification'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.copyOfIdenification,
      callback: function ($$v) {
        _vm.$set(_vm.form, "copyOfIdenification", $$v);
      },
      expression: "form.copyOfIdenification"
    }
  })], 1), _c('v-row', {
    staticClass: "deal-type",
    staticStyle: {
      "padding-left": "32px",
      "padding-right": "32px",
      "margin-top": "14px"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('BackgroundCheck'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.backgroundCheck,
      callback: function ($$v) {
        _vm.$set(_vm.form, "backgroundCheck", $$v);
      },
      expression: "form.backgroundCheck"
    }
  })], 1), _c('v-row', {
    staticClass: "required",
    staticStyle: {
      "padding-left": "32px",
      "margin-top": "32px"
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("PoliticallyExposedPerson")))])]), _c('v-row', {
    staticClass: "deal-type",
    staticStyle: {
      "padding-left": "32px",
      "padding-right": "32px",
      "margin-top": "14px"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": "",
      "error": _vm.isErrorPoliticallyExposedPerson
    },
    model: {
      value: _vm.form.politicallyExposedPerson,
      callback: function ($$v) {
        _vm.$set(_vm.form, "politicallyExposedPerson", $$v);
      },
      expression: "form.politicallyExposedPerson"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('Yes'),
      "value": 1
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('No'),
      "value": 0
    }
  })], 1)], 1), _vm.isErrorPoliticallyExposedPerson ? _c('v-row', {
    staticClass: "error-text",
    staticStyle: {
      "margin": "8px 0 0 32px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("FormErrors.FieldRequired")) + " ")]) : _vm._e(), _c('v-row', {
    staticClass: "required",
    staticStyle: {
      "padding-left": "32px"
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("SubjectOfSanctions")) + " ")])]), _c('v-row', {
    staticClass: "deal-type",
    staticStyle: {
      "padding-left": "32px",
      "padding-right": "32px",
      "margin-top": "14px"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": "",
      "error": _vm.isErrorSubjectOfSanctions,
      "hide-details": ""
    },
    model: {
      value: _vm.form.subjectOfSanctions,
      callback: function ($$v) {
        _vm.$set(_vm.form, "subjectOfSanctions", $$v);
      },
      expression: "form.subjectOfSanctions"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('Yes'),
      "value": 1
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('No'),
      "value": 0
    }
  })], 1)], 1), _vm.isErrorSubjectOfSanctions ? _c('v-row', {
    staticClass: "error-text",
    staticStyle: {
      "margin": "8px 0 0 32px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("FormErrors.FieldRequired")) + " ")]) : _vm._e(), _c('div', {
    staticStyle: {
      "padding-top": "16px",
      "padding-bottom": "200px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('button', {
    staticClass: "add-files-button",
    staticStyle: {
      "border": "1px dashed #000000",
      "width": "112px",
      "height": "32px"
    },
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.uploadFile();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/attach.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "content-small",
    staticStyle: {
      "font-weight": "400",
      "min-width": "64px !important",
      "justify-content": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AttachFiles")) + " ")])]), _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "sub-block"
  }, [_vm.isUploading ? _c('div', {
    staticClass: "overlay"
  }, [_vm._m(0)]) : _vm._e(), _vm._l(_vm.form.files, function (el, index) {
    return _c('v-row', {
      key: index,
      staticClass: "file-row",
      staticStyle: {
        "margin-top": "16px"
      }
    }, [_c('span', {
      staticStyle: {
        "display": "flex",
        "gap": "8px"
      }
    }, [_c('img', {
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "src": require("../../assets/images/action-delete.svg"),
        "alt": ""
      },
      on: {
        "click": function ($event) {
          return _vm.deleteFile(el, true);
        }
      }
    }), _c('span', {
      staticClass: "file-name",
      staticStyle: {
        "text-decoration": "none"
      }
    }, [_vm._v(" " + _vm._s(el.name) + " ")])])]);
  }), _c('input', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "id": 'aml-file-uploader',
      "multiple": ""
    },
    on: {
      "change": function (event) {
        return _vm.uploadFiles(event);
      }
    }
  }), _c('a', {
    attrs: {
      "href": "",
      "id": "download-link",
      "download": ""
    }
  })], 2)])]), _c('div', {
    staticClass: "buttons-container"
  }, [_c('v-divider', {
    staticStyle: {
      "z-index": "111",
      "position": "fixed !important",
      "width": "560px"
    }
  }), _c('div', {
    staticClass: "buttons-row",
    staticStyle: {
      "padding-left": "24px"
    }
  }, [_c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('button', {
    staticClass: "save-button",
    on: {
      "click": function ($event) {
        return _vm.saveAml(_vm.form);
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/Submit.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")])])], 1)], 1)]), _vm.isDeleteModalOpen ? _c('DeleteModal', {
    attrs: {
      "removing": false
    },
    on: {
      "canceled": function ($event) {
        _vm.isDeleteModalOpen = false;
      },
      "approved": function ($event) {
        return _vm.deleteAml();
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay__inner"
  }, [_c('div', {
    staticClass: "overlay__content"
  }, [_c('span', {
    staticClass: "spinner"
  })])]);

}]

export { render, staticRenderFns }